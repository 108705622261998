module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Handy Telefonsex","short_name":"Handy Telsex","start_url":"/","background_color":"#ee7b7f","theme_color":"#ee7b7f","display":"minimal-ui","icon":"src/images/telefonsex-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2efb9e14057d325ad1770a5825ad71bb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://meintelefonsexohne0900.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
